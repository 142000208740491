import { difference, get, head, isArray, isEqual, isNaN, isNumber, toLower } from 'lodash';
import Dispatch from '@/class/Dispatch';
import {
  approveInternalSspUnit,
  approveExternalSspUnit,
  blockSspUnit,
  deleteSspUnit,
  detailExternalSspApprovedUnit,
  detailInternalSspApprovedUnit,
  detailBlockSspUnit,
  getSspEpIntegrationConfig,
} from '@/models/operation/ssp/api';
import { DspOperationStateEnum } from '@/models/operation/ssp/enum';
import Utils from '@/utils';
import DspMultipleApproveService from '@/pages/operation/dsp/approve/service';
import DspUnitService from '@/pages/operation/dsp/service';
import NumberFormatService from '@/services/numberformat.service';
import AdSetConstant from '@/pages/rtb/ad-set/ad-set.constant';
import BySspBudgetUnitService from '@/pages/rtb/by-ssp-unit/service';
import DspSspOperationService from '@/pages/operation/operation.service';
import { OperationLogModule } from '@/models/operation-log/enum';
import OperationLogService from '@/models/operation-log/operation-log.service';

class SspUnitService extends Dispatch {
  listSspUnit(params) {
    this.dispatch({
      type: 'operationSspUnitState/listSspUnit',
      payload: params,
    });
  }

  listOperationDspApproveUnit(params) {
    this.dispatch({
      type: 'operationSspUnitState/listOperationDspApproveUnit',
      payload: params,
    });
  }

  listOperationDspBlockUnit(params) {
    this.dispatch({
      type: 'operationSspUnitState/listOperationDspBlockUnit',
      payload: params,
    });
  }

  updateOperationState(params) {
    this.dispatch({
      type: 'operationSspUnitState/updateOperationState',
      payload: params,
    });
  }

  updateFilterFormDspOptions(list) {
    this.dispatch({
      type: 'operationSspUnitState/updateFilterFormDspOptions',
      payload: list,
    });
  }

  updateApprovedDspOptions(list) {
    this.dispatch({
      type: 'operationSspUnitState/updateApprovedDspOptions',
      payload: list,
    });
  }

  listDspOffer(params) {
    this.dispatch({
      type: 'operationSspUnitState/listDspOffer',
      payload: params,
    });
  }

  listInternalDspEp(params) {
    this.dispatch({
      type: 'operationSspUnitState/listInternalDspEp',
      payload: params,
    });
  }

  // 获取ep config
  static getSspEpIntegrationConfig(integrationId) {
    return getSspEpIntegrationConfig(integrationId);
  }

  static async approveSspUnit({ internal, external }) {
    let internalResponse = Promise.resolve();

    let externalResponse;

    try {
      if (internal) {
        if (
          internal.internalDspOperationSwitch === DspOperationStateEnum.ON &&
          !internal.internalDspSspOfferAdSetBudgets.length
        ) {
          return;
        }

        internalResponse = await approveInternalSspUnit(internal);
      }
    } catch (err) {}

    try {
      externalResponse = external ? await approveExternalSspUnit(external) : Promise.resolve();
    } catch (err) {}

    return internalResponse || externalResponse;
  }

  static blockSspUnit(body) {
    return blockSspUnit(body);
  }

  static deleteSspUnit(body) {
    return deleteSspUnit(body);
  }

  static async detailApproveSspUnit(params) {
    const internal = await detailInternalSspApprovedUnit(params);

    const external = await detailExternalSspApprovedUnit(params);

    return {
      internal,
      external,
    };
  }

  static detailBlockSspUnit(params) {
    return detailBlockSspUnit(params);
  }

  static deleteOfferAdsetDspSspBudget(ids) {
    return BySspBudgetUnitService.deleteSspBudgetUnit(ids);
  }

  static reFloatRule(sspOperationId) {
    return DspUnitService.reFloatRule(sspOperationId);
  }

  static getOperationParams(item) {
    if (!Utils.falsely(item)) {
      const { sspIntegrationId, countryId, os, adFormat } = item;

      return {
        sspIntegrationId,
        countryId,
        os,
        adFormat,
      };
    }

    return {
      sspIntegrationId: null,
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  static generalDspSortList(ids) {
    if (isArray(ids)) {
      return ids.map((id) => ({
        dspIntegrationId: id,
        time: +new Date(),
      }));
    }

    return [];
  }

  static mergeConfigBlockDetail(config, detail) {
    const existConfig = config && config.blockCreativeIds?.length;

    if (existConfig && !detail) {
      const { blockCreativeIds } = config;

      return {
        ...config,
        dspUnits: [],
        creativeIds: blockCreativeIds,
      };
    }

    const { dspOperations, ...rest } = detail || {};

    return {
      ...rest,
      dspUnits: isArray(dspOperations) ? dspOperations.map((item) => item.dspIntegrationId) : [],
    };
  }

  static checkAvailableBlockConfig(blockData) {
    return blockData && !!blockData.creativeIds?.length;
  }

  // 审批详情转换成表单格式
  static sspUnitApproveDetailToForm(detail, ssp) {
    const { internal = {}, external = {} } = detail;

    const { internalDspOperationSwitch, internalDspSspOfferAdSetBudgets, ...restInternal } =
      internal || {};

    const { externalDspOperations, ...restExternal } = external || {};

    const externalDspEp = [];

    const mergeExternalDspOperations = [];

    const mergeInternalDspSspOfferAdSetBudgets = isArray(internalDspSspOfferAdSetBudgets)
      ? internalDspSspOfferAdSetBudgets
          .filter((item) => (isNumber(item.budget) ? item.budget > 0 : true))
          .map((item) => {
            const {
              offerId,
              offerName,
              adSetId,
              adSetName,
              adFormat,
              budget,
              payoutIncrease = 0,
              bidRates,

              ...rest
            } = item;

            const id = SspUnitService.generateOfferAdsetId(item);

            return {
              ...rest,
              offerId,
              offerName,
              adSetId,
              adSetName,
              adFormat,
              budget,
              payoutIncrease,
              dspOfferId: id,
              bidRates:
                isArray(bidRates) && bidRates.length ? bidRates : AdSetConstant.defaultBidRate,
            };
          })
      : [];

    if (isArray(externalDspOperations) && externalDspOperations.length) {
      externalDspOperations.forEach((item) => {
        const dspIntegrationId = item.dspIntegrationId;

        externalDspEp.push(dspIntegrationId);

        const {
          qps,
          floorIncrease,
          payoutDecrease,
          fixed,
          floated,
          opened,
          qpsFloatRulesName,
          id,
          dspSspApproveId,
        } = item;

        const qpsFloatRule = {
          ruleId: floated,
          originRuleId: floated,
          fixed,
          qpsFloatRulesName,
          opened,
        };

        const qpsRuleType = DspUnitService.mergeQpsRuleType(item);

        mergeExternalDspOperations.push({
          dspSspApproveId,
          qpsRuleType,
          id,
          qps,
          floorIncrease,
          payoutDecrease,
          qpsFloatRule,
          dspIntegrationId,
        });
      });
    }

    // 合并bid price range
    const { bidPriceRangeMin, bidPriceRangeMax } =
      !external || !Utils.falsely(external.bidPriceRangeMin)
        ? {
            bidPriceRangeMin: ssp?.bidPriceRangeMin || 0,
            bidPriceRangeMax: ssp?.bidPriceRangeMax || 9999,
          }
        : external;

    const uniqueBudgetList = Utils.uniqueArrayByKey(
      mergeInternalDspSspOfferAdSetBudgets,
      'dspOfferId',
    );

    const dspOfferId = uniqueBudgetList.map((item) => item.dspOfferId);

    return {
      externalDspEp,
      restInternal,
      restExternal,

      dspOfferId,
      internalDspOperationSwitch: internalDspOperationSwitch === DspOperationStateEnum.ON,
      bidPriceRangeMin: isNumber(bidPriceRangeMin) && bidPriceRangeMin >= 0 ? bidPriceRangeMin : 0,
      bidPriceRangeMax: isNumber(bidPriceRangeMax) ? bidPriceRangeMax : 9999,
      externalDspOperations: mergeExternalDspOperations,
      internalDspSspOfferAdSetBudgets: uniqueBudgetList,
    };
  }

  static getApprovedDspOptions(list) {
    if (isArray(list)) {
      return list.map((item) => {
        const { dspIntegrationId, dspUnit } = item;

        return {
          id: dspIntegrationId,
          name: dspUnit,
        };
      });
    }

    return [];
  }

  static sortDspOperations(operations, sortList) {
    if (isArray(operations) && isArray(sortList) && sortList.length) {
      return operations
        .map((item) => {
          const sorter = sortList.find((child) => child.dspIntegrationId === item.dspIntegrationId);

          if (sorter) {
            return {
              ...item,
              updateTime: sorter.time,
            };
          }

          return item;
        })
        .sort((a, b) => {
          return b.updateTime - a.updateTime;
        });
    }

    return operations;
  }

  // 聚合id
  static generatorOperationId(item) {
    const { id: sspIntegrationId, countryId, os, adFormat, sspId } = item;

    return [sspIntegrationId, countryId, os, adFormat, sspId].join('_');
  }

  // 解析聚合id
  static decodeOperationId(operationId) {
    if (operationId && operationId.includes('_')) {
      const [sspIntegrationId, countryId, os, adFormat] = operationId
        .split('_')
        ?.map((item) => DspMultipleApproveService.number(item));

      return {
        sspIntegrationId,
        countryId,
        os,
        adFormat,
      };
    }

    return {
      sspIntegrationId: null,
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  // 聚合dsp offer id
  static generateOfferAdsetId(item) {
    const { offerId, adSetId, adFormat } = item;

    return `${offerId}_${adSetId}_${adFormat}`;
  }

  // 解析dsp offer id
  static parseOfferAdsetId(string) {
    if (string && string.includes('_')) {
      const [offerId, adSetId, adFormat] = string.split('_');

      return {
        offerId: !isNaN(offerId) ? +offerId : offerId,
        adSetId: !isNaN(adSetId) ? +adSetId : adSetId,
        adFormat,
      };
    }

    return {
      offerId: null,
      adSetId: null,
      adFormat: null,
    };
  }

  static transformOfferStatusWithApprovedDspEp(
    dspOffer,
    internalDspEpList,
    internalDspAggregationConfigList,
  ) {
    // dspOffer
    // internalDspEpList 匹配的内部52 ep
    // internalDspAggregationConfigList 再opa b里面审批过的dsp ep

    const approvedInternalDspEpIds = isArray(internalDspAggregationConfigList)
      ? internalDspAggregationConfigList.map((item) => item.dspIntegrationId)
      : [];

    const matchedOffer = head(dspOffer.filter((item) => !item.disabled));

    return internalDspEpList.filter((dsp) => {
      const isApproved = approvedInternalDspEpIds.length
        ? approvedInternalDspEpIds.includes(dsp.dspIntegrationId)
        : false;

      return dsp.matchedId === matchedOffer?.matchedId && !isApproved;
    });
  }

  // 内部被审批的dsp表单数据转换成提交数据
  static internalDspOperations2Body(
    list,
    internalDspEpList,
    dspOffer,
    ssp,
    internalDspAggregationConfigList,
  ) {
    if (isArray(list) && list.length) {
      const matchedAndNotApprovedDspEp = SspUnitService.transformOfferStatusWithApprovedDspEp(
        dspOffer,
        internalDspEpList,
        internalDspAggregationConfigList,
      );

      const matchedInternalDspEpList = Utils.uniqueArrayByKey(
        matchedAndNotApprovedDspEp.map((dsp) => {
          return {
            ...dsp,
            qps: 1000,
            floorIncrease: 0,
            payoutDecrease: 0,
            fixed: true,
            floated: -1,
            opened: 1,
            reFloated: false,
          };
        }),
        'dspIntegrationId',
      );

      const offerAdSetBudgets = [];

      matchedInternalDspEpList.forEach((dsp) => {
        list.forEach((item) => {
          const { dspOfferId, payoutIncrease = 0, bidRates, ...rest } = item;

          const offerItem = dspOffer.find((offer) => offer.id === dspOfferId);

          if (offerItem) {
            const { offerId, adSetId, adFormat } = SspUnitService.parseOfferAdsetId(dspOfferId);

            const { countryId, os, sspId, sspIntegrationId } = ssp;

            const budget = {
              ...rest,
              ...dsp,
              offerId,
              adSetId,
              adFormat,
              countryId,
              os,
              sspId,
              sspIntegrationId,
              bidRates:
                isArray(bidRates) && bidRates.length ? bidRates : AdSetConstant.defaultBidRate,
              payoutIncrease: NumberFormatService.toFixed(payoutIncrease),
            };

            offerAdSetBudgets.push(budget);
          }
        });
      });

      return {
        internalDspList: matchedInternalDspEpList,
        offerAdSetBudgets,
      };
    }

    return {
      internalDspList: [],
      offerAdSetBudgets: [],
    };
  }

  // 找出被移除的预算
  static getNeedDeleteSspBudget(detailDspOperations, formDspOperations) {
    // 历史预算id
    const ids = isArray(detailDspOperations)
      ? detailDspOperations.filter((item) => !Utils.falsely(item.id)).map((item) => item.id)
      : [];

    // 表单提交还剩余的id
    const formDspOperationIds = isArray(formDspOperations)
      ? formDspOperations.filter((item) => !Utils.falsely(item.id)).map((item) => item.id)
      : [];

    if (ids.length) {
      return formDspOperationIds.length === 0
        ? ids
        : ids.filter((id) => !formDspOperationIds.includes(id));
    }

    return [];
  }

  // 跟内部dsp不匹配的offer
  static disableNotMatchedInternalDspEp(internalDspEps, dspOffers) {
    if (isArray(dspOffers) && dspOffers.length) {
      const matchedIds = isArray(internalDspEps)
        ? internalDspEps.map((item) => item.matchedId)
        : [];

      return dspOffers.map((offer) => {
        const offerMatchedId =
          offer.matchedId || SspUnitService.generateMatchedInternalDspKey(offer);

        const disabled = !matchedIds.includes(offerMatchedId);

        return {
          ...offer,
          disabled,
        };
      });
    }

    return [];
  }

  static generateMatchedInternalDspKey(item) {
    const { countryId, os, adFormat } = item;

    return `${countryId}_${os}_${adFormat}`;
  }

  static parseMatchedInternalDspKey(string) {
    if (string && string.includes('_')) {
      const [countryId, os, adFormat] = string.split('_');

      return {
        countryId,
        os,
        adFormat,
      };
    }

    return {
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  static checkExistedBudgetEqualsZero(list) {
    if (isArray(list)) {
      return list.some((item) => item.hasOwnProperty('budget') && item.budget === 0);
    }

    return false;
  }

  // 是否dsp ep 跟ssp是否是同一个品牌
  static transformDspEpItem(dspEpList, configList, ssp) {
    if (isArray(dspEpList)) {
      const approvedDspEpIds = isArray(configList)
        ? configList.map((item) => item.dspIntegrationId)
        : [];

      return dspEpList.map((item) => {
        const dspBrand = DspUnitService.getBrandNameFromUnit(item.dspUnit);

        const sspBrand = DspUnitService.getBrandNameFromUnit(ssp.sspUnit);

        const isApproved = approvedDspEpIds.length
          ? approvedDspEpIds.includes(item.dspIntegrationId)
          : false;

        // 同品牌禁用，自己不给批给自己
        const dspSspSameBrand = toLower(dspBrand) === toLower(sspBrand);

        const reasonObj = {
          sameBrand: dspSspSameBrand,
          approved: isApproved,
        };

        const reason = DspSspOperationService.getDisableReason(reasonObj);

        return {
          ...item,
          disabled: dspSspSameBrand || isApproved,
          title: reason.join('.\n'),
        };
      });
    }

    return [];
  }

  static getDspOperationEffectMemos(changedFields, formValues, updateTarget) {
    const getValueDiff = (oldValue, value) => {
      const action = value.length > oldValue.length;

      const diffValue = action ? difference(value, oldValue) : difference(oldValue, value);

      return {
        value: diffValue,
        action: action ? 'Add' : 'Remove',
      };
    };

    const changed = [];

    const collectionDspMemos = (changedDsp) => {
      const { name, value, plug } = changedDsp || {};

      const oldValue = get(formValues, name);

      if (!isEqual(oldValue, value)) {
        const diff = isArray(value)
          ? getValueDiff(oldValue, value)
          : {
              value,
              action: 'Add',
            };

        if (isArray(diff.value)) {
          diff.value.forEach((dspId) => {
            const changedRecord = {
              plug,
              action: `From OPA SSP ${diff.action}`,
              path: name,
              from: null,
              to: updateTarget.sspIntegrationId,
            };

            changed.push({
              os: updateTarget.os,
              countryId: updateTarget.countryId,
              dspIntegrationId: dspId,
              content: {
                operations: [changedRecord],
              },
              page: OperationLogModule.DspOperation,
            });
          });
        }
      }
    };

    const collectionDspOptionMemos = (changedDspOperation) => {
      const { name, value, plug } = changedDspOperation || {};

      const oldValue = get(formValues, name);

      if (!isEqual(oldValue, value)) {
        const changedRecord = {
          plug,
          action: 'From OPA SSP Update',
          path: name,
          from: oldValue,
          to: value,
        };

        const hasDspMemoItem = changed.some(
          (dspMemoItem) => dspMemoItem.dspIntegrationId === plug.dspIntegrationId,
        );

        if (hasDspMemoItem) {
          changed.forEach((changedItem) => {
            if (changedItem.dspIntegrationId === plug.dspIntegrationId) {
              changedItem.content.operations.push(changedRecord);
            }
          });

          return;
        }

        changed.push({
          os: updateTarget.os,
          countryId: updateTarget.countryId,
          dspIntegrationId: plug.dspIntegrationId,
          content: {
            operations: [changedRecord],
          },
          page: OperationLogModule.DspOperation,
        });
      }
    };

    if (formValues && changedFields && changedFields.length) {
      changedFields.forEach((item) => {
        const changeName = head(item.name);

        if (changeName === 'externalDspEp') {
          collectionDspMemos(item);
        }

        if (
          changeName === 'externalDspOperations' &&
          OperationLogService.isListNameField(item.name)
        ) {
          collectionDspOptionMemos(item);
        }
      });
    }

    return changed;
  }
}

export default SspUnitService;
