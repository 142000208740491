import { isArray, isNumber } from 'lodash';

export function transformSspUnit(data) {
  if (data) {
    const { list, summary } = data;

    const totalBudget = summary?.budget || 0;

    const budget =
      isArray(list) && list.length
        ? list.reduce((num, item) => {
            num += isNumber(item.budget) ? item.budget : 0;

            return num;
          }, 0)
        : 0;

    return {
      ...data,
      summary: {
        ...summary,
        budget,
        totalBudget,
      },
    };
  }

  return {
    list: [],
    paginator: {},
    summary: {},
  };
}
