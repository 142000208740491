import {
  uploadOperationLog,
  listDspOperationLog,
  listSspOperationLog,
  listNewOperationDspEpLog,
  listNewOperationSspEpLog,
} from './api';
import { get, isEqual, isArray, isNumber, difference } from 'lodash';
import { OperationLogModule, UpdateAction } from '@/models/operation-log/enum';

class OperationLogService {
  // 字段变动记录
  changeFormFields;

  // 当前作用的模块
  module;

  constructor(module) {
    this.changeFormFields = [];

    this.module = module;
  }

  // 更新变动字段
  updateChangedFields(changed, plug) {
    const findIndex = this.changeFormFields.findIndex((item) => isEqual(item.name, changed.name));

    const item = {
      ...changed,
      plug,
    };

    // 新增
    if (findIndex <= -1) {
      this.changeFormFields.push(item);

      return;
    }

    // 重复记录只保留最新一条
    this.changeFormFields.splice(findIndex, 1, item);
  }

  getChangeFormFields() {
    return this.changeFormFields;
  }

  getModule() {
    return this.module;
  }

  // 如果是大数据数组，找出差异
  getValueDiff(oldValue, value) {
    const action = value.length > oldValue.length;

    const diffValue = action ? difference(value, oldValue) : difference(oldValue, value);

    return {
      diffValue,
      diffAction: action ? 'plus' : 'minus',
    };
  }

  // 根据表单初始值和变动记录 找出前后变动记录
  getDiffFromNamePath(data, action) {
    const changed = [];

    const changedPaths = this.changeFormFields;

    if (data && changedPaths && changedPaths.length) {
      changedPaths.forEach((path) => {
        const { name, value, plug } = path;

        const oldValue = get(data, name);

        if (!isEqual(oldValue, value)) {
          const valueAction =
            isArray(oldValue) && isArray(value)
              ? this.getValueDiff(oldValue, value)
              : {
                  diffValue: value,
                  diffAction: 'plus',
                };

          changed.push({
            ...valueAction,

            plug,
            action: action || UpdateAction.Update,
            path: name,
            from: oldValue,
            to: value,
          });
        }
      });
    }

    return changed;
  }

  // 是否是Item.List name 批量
  static isListNameField(name) {
    if (isArray(name) && name.length) {
      if (name.length === 1) {
        return false;
      }

      const [first, ...restFields] = name;

      return restFields.some((field) => isNumber(field));
    }

    return false;
  }

  // 整合上传日志接口
  uploadOperationLog(body) {
    return uploadOperationLog({ ...body, page: this.module });
  }

  // 通用上传
  uploadLog(memos) {
    return uploadOperationLog(memos);
  }

  // 查询上传日志接口
  listOperationLog(params) {
    if (this.module === OperationLogModule.DspOperation) {
      return listDspOperationLog(params);
    }

    if (this.module === OperationLogModule.SspOperation) {
      return listSspOperationLog(params);
    }

    if (this.module === OperationLogModule.NewDspEpOperation) {
      return listNewOperationDspEpLog(params);
    }

    if (this.module === OperationLogModule.NewSspEpOperation) {
      return listNewOperationSspEpLog(params);
    }

    return Promise.resolve([]);
  }
}

export default OperationLogService;
