import { isArray } from 'lodash';
import request from '@/http';

export function uploadOperationLog(memos) {
  const url = '/api/adx/common/operation/memo/upload/batch';

  return request.post(url, {
    data: isArray(memos) ? memos : [memos],
  });
}

// operation dsp log
export function listDspOperationLog(params) {
  const url = '/api/adx/operation/dsp/memo/list';

  return request.get(url, {
    params,
  });
}

// operation ssp log
export function listSspOperationLog(params) {
  const url = '/api/adx/operation/ssp/memo/list';

  return request.get(url, {
    params,
  });
}

// new operation dsp ep log
export function listNewOperationDspEpLog(param) {
  const { dspIntegrationId, ...rest } = param;

  const url = `/api/adx/operation/dsp/ep/${dspIntegrationId}/aggregation/memo/list`;

  return request.get(url, {
    params: {
      ...rest,
    },
  });
}

// new operation ssp ep log
export function listNewOperationSspEpLog(param) {
  const { sspIntegrationId, ...rest } = param;

  const url = `/api/adx/operation/ssp/ep/${sspIntegrationId}/aggregation/memo/list`;

  return request.get(url, {
    params: {
      ...rest,
    },
  });
}
